import React, {useEffect, useRef, useState} from 'react';
import {Button, Table} from 'react-bootstrap';
import ActivityConfig from './config'; // Certifique-se de importar o componente ActivityConfig
import ActivityInfo from './info'; // Certifique-se de importar o componente ActivityInfo
import ActivityVerbs from './verbs'; // Importe o componente ActivityVerbs
import ActivityPoints from './points'; // Importe o componente ActivityPoints
import DownloadLinks from "./download";
import './App.css'
// import logo from './logotipo2.png'; // O caminho para a imagem
// import paypalIcon from "./Icons/paypalIcon.png";
import helpIcon from "./Icons/helpIcon.png";
import {playerNewList, playerPlay} from "./musicPlayer";

const MainActivity = () => {
  const logoRef=useRef('./logotipo2.png');
  const paypalIconRef=useRef('./Icons/paypalIcon.png');
  const audiosUrlRef = useRef([]); // Adicione esta referência no início do seu componente
  const audiosRef = useRef([]);
  // const hasRunRef = useRef(false);
  //const helpIconRef= useRef(require ("./Icons/helpIcon.png"));
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [isModalVisible, setIsModalVisible] = useState(true);

  useEffect(() => {
    //Obs: reescrever o arquivo config para o caso de corrupções ou novas versões -I
    let setConfig = {
      NumeroAudios: '0',
      PaisAudio: 'Brasil',
      LerTempo: 'Uma Vez',
      VelocidadeAudio: '100'
    };

    console.log(setConfig);
    const savedConfig = localStorage.getItem('config');
    console.log(1);

    if (savedConfig) {
      setConfig=JSON.parse(savedConfig);
      if(setConfig.NumeroAudios===undefined ){
        setConfig.NumeroAudios='0';
      } else if(setConfig.NumeroAudios===null){
        setConfig.NumeroAudios='0';
      } else if(setConfig.NumeroAudios===''){
        setConfig.NumeroAudios='0';
      }

      if(setConfig.PaisAudio===undefined ){
        setConfig.PaisAudio='Brasil';
      } else if(setConfig.PaisAudio===null){
        setConfig.PaisAudio='Brasil';
      } else if(setConfig.PaisAudio===''){
        setConfig.PaisAudio='Brasil';
      }

      if(setConfig.LerTempo===undefined ){
        setConfig.LerTempo='Uma Vez';
      } else if(setConfig.LerTempo===null){
        setConfig.LerTempo='Uma Vez';
      } else if(setConfig.LerTempo===''){
        setConfig.LerTempo='Uma Vez';
      }

      if(setConfig.VelocidadeAudio===undefined ){
        setConfig.VelocidadeAudio='100';
      } else if(setConfig.VelocidadeAudio===null){
        setConfig.VelocidadeAudio='100';
      } else if(setConfig.VelocidadeAudio===''){
        setConfig.VelocidadeAudio='100';
      }
    }

    console.log(4);
    localStorage.setItem('config', JSON.stringify(setConfig));
    console.log(setConfig);
    //Obs: reescrever o arquivo config para o caso de corrupções ou novas versões -F
  }, []); // Dependências vazias significam que isso só será executado na montagem do componente

  const [page, setPage] = useState('main');


  const handleButton1Click = () => {
    setPage('config');
  };

  const handleButton2Click = () => {
    // Navegue para a página de verbos
    setPage('verbs');
  };

  const handleButton10Click = () => {
    // Navegue para a página de pontos
    setPage('points');
  };

  const handleInfoClick = () => {
    setPage('info');
  };

  const handleHelpClick1 = () => {
    // Navegue para a página de ajuda
    window.open('https://www.youtube.com/watch?v=u11TydhiNzE', '_blank');
  };

  const handleAppDownloadClick = () => {
    // window.open('https://www.amazon.com.br/Leonardo-Rafael-Verbos-PT/dp/B0D2LT7L47/ref=sr_1_1?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=1WDJONLDBFL9B&dib=eyJ2IjoiMSJ9.UDQ8YGsxvy68vdZQzk6OzkjzOCNg-uCF9iIZpYdAfVLFSDyuSwuCSWzDD2p2EmyD1o5UVM-mAvTqmzAr-XXIEw.zvVozDP9DC7EnIQgmGeG3VrmpIlEZ5fNKs4EtiZP294&dib_tag=se&keywords=Verbos+PT&qid=1716486415&s=mobile-apps&sprefix=verbos+pt%2Cmobile-apps%2C296&sr=1-1', '_blank');
    setPage('download');
  };

  // Função para fechar o modal
  const closeWelcomeModal = async () => {
    setIsModalVisible(false);
    const urlAudio000 =  process.env.PUBLIC_URL + `/raw/aviso.mp3`
    audiosUrlRef.current=[urlAudio000];
    audiosRef.current = audiosUrlRef.current.map((url) => ({
      name: 'Aviso',
      artist: 'Leonardo Pereira',
      url: url,
    }));
    await playerNewList(audiosRef.current)
    playerPlay(); // Executa a função playerPlay
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    // Adiciona um listener para atualizar a largura e altura da tela
    window.addEventListener('resize', handleResize);

    // Remove o listener quando o componente for desmontado
    return () => window.removeEventListener('resize', handleResize);
  });

  if (page === 'main') {
    return (
        <div className="App">
          {/* Modal de boas-vindas */}
          {isModalVisible && ( // Renderiza o modal somente se hasRunRef.current for false
              <div id="welcome-modal" className="modal">
                <div className="modal-content">
                  <h2>Bem-vindo ao Memorizador de Verbos!</h2>
                  <p>
                    Clique no botão abaixo para começar a explorar o aplicativo!
                  </p>
                  <button onClick={closeWelcomeModal} className="modal-button">
                    Começar
                  </button>
                </div>
              </div>
          )}
          <div className="column left" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
            <h2>Em construção...</h2>
            <table>
              <tbody>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">Doação:</a></h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>

                <td style={{height: '5px'}}>
                  <p><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">
                    <img src={require(`${paypalIconRef.current}`)} alt="Ícone do PayPal" style={{width: '200px', height: '200px'}}/>
                  </a></p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div className="column center" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <h3 style={{marginTop: '0'}}>MEMORIZADOR DE VERBOS</h3>
            <h4 style={{color: 'red'}}> ({screenSize.width}x{screenSize.height})</h4>
            <img src={require(`${logoRef.current}`)}
                 alt="Logo"
                 style={{marginBottom: '28px', width: '250px', height: '250px'}}/>
            <Button className='button1' onClick={handleButton1Click} style={{bottom: '185px'}}>Configurações</Button>
            <Button className='button1' onClick={handleButton2Click} style={{bottom: '140px'}}>Conjugações</Button>
            <Button className='button1' onClick={handleButton10Click} style={{bottom: '95px'}}>Situações
              Pontuais</Button>
            <Button className='button1' onClick={handleInfoClick} style={{bottom: '50px'}}>Informações</Button>
            <Table style={{position: 'absolute', bottom: '1px'}}>
              <tbody>
              <tr>
                <td style={{display: 'flex', justifyContent: 'center'}}>
                  <Button id="ajudaA" className='button3' onClick={handleHelpClick1}
                          style={{backgroundImage: `url(${helpIcon})`}}></Button>
                </td>
                <td><Button className='button2' onClick={handleAppDownloadClick}>Baixar APP</Button></td>
                <td style={{display: 'flex', justifyContent: 'center'}}>
                  <Button id="ajudaB" className='button3' onClick={handleHelpClick1}
                          style={{backgroundImage: `url(${helpIcon})`}}></Button>
                </td>
              </tr>
              </tbody>
            </Table>
          </div>
          <div className="column right">
            <table>
            <tbody>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3 style={{ marginBottom: '0'}}>Configurações:</h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px'}}>
                  <p style={{ marginBottom: '0', marginTop: '0'}}>Você poderá configurar as interações do aplicativo de acordo com a sua preferência. Este botão é especialmente útil se você quiser ouvir as conjugações.</p>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3 style={{ marginBottom: '0'}}>Conjugações:</h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px'}}>
                  <p style={{ marginBottom: '0', marginTop: '0'}}>Você verá vários botões com tipos de verbos. Cada um desses tipos terá um número específico de paradigmas, que são verbos que servem de uma base para a conjugação de outros verbos, quer sejam regulares ou irregulares.</p>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3 style={{ marginBottom: '0'}}>Situações Pontuais:</h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px'}}>
                  <p style={{ marginBottom: '0', marginTop: '0'}}>Existem situações excecionais isoladas. Alguns verbos perdem o "E" final em algumas conjugações; outros têm o particípio passado irregular; e outros, dois particípios passados: um regular e outro irregular. Estas características são estudadas aqui de forma especial, pois os verbos paradigmáticos para estes aspectos são diferentes dos verbos paradigmáticos mostrados para as conjugações em geral. Este aplicativo é feito para memorizar e por isso tentamos não multiplicar o número de paradigmas.</p>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3 style={{ marginBottom: '0'}}>Informações:</h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px'}}>
                  <p style={{ marginBottom: '0', marginTop: '0'}}>Você verá mais informações sobre o aplicativo e algumas hiperligações.</p>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px', hyphens: "none"}}>
                  <h3 style={{ marginBottom: '0'}}>Ajuda:</h3>
                </td>
              </tr>
              <tr style={{height: '5px'}}>
                <td style={{height: '5px'}}>
                  <p style={{ marginBottom: '0', marginTop: '0'}}>Acesso a um vídeo explicativo, sobre este aplicativo, localizado no nosso canal do YouTube.</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
    );
  } else if (page === 'config') {
    return <ActivityConfig goBack={() => setPage('main')} />;
  } else if (page === 'info') {
    return <ActivityInfo goBack={() => setPage('main')} />;
  } else if (page === 'verbs') {
    return <ActivityVerbs goBack={() => setPage('main')} />;
  } else if (page === 'points') {
    return <ActivityPoints goBack={() => setPage('main')} />;
  } else if (page === 'download') {
    return <DownloadLinks goBack={() => setPage('main')} />;
  }

};


export default MainActivity;
