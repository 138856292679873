import React, { useRef } from 'react';

const DownloadLinks = ({ goBack }) => {
    const logoRef = useRef('./logotipo2.png');
    const paypalIconRef = useRef('./Icons/paypalIcon.png');

    return (
        <div className="App">
            <div className="column left" style={{ alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <table>
                    <tbody>
                    <tr style={{ height: '5px' }}>
                        <td style={{ height: '5px', hyphens: 'none' }}>
                            <h3><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">Doação:</a></h3>
                        </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                        <td style={{ height: '5px' }}>
                            <p><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${paypalIconRef.current}`)} alt="Ícone do PayPal" style={{ width: '200px', height: '200px' }} />
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="column center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <table style={{ marginBottom: '20px', margin: 'auto' }}>
                    <tbody>
                    <tr style={{ height: '5px' }}>
                        <td style={{ height: '5px', hyphens: 'none', textAlign: 'center' }}>
                            <h3>Baixe a versão leve e incompleta do aplicativo para Android:</h3>
                            <a href="https://play.google.com/store/apps/details?id=com.apkomp.verbospt" target="_blank" rel="noopener noreferrer">Android (Leve, Incompleta)</a>
                        </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                        <td style={{ height: '5px', hyphens: 'none', textAlign: 'center' }}>
                            <h3>Baixe a versão pesada e completa do aplicativo para Android:</h3>
                            <a href="https://play.google.com/store/apps/details?id=com.apkomp.verbosptrnand" target="_blank" rel="noopener noreferrer">Android (Pesada, Completa)</a>
                        </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                        <td style={{ height: '5px', hyphens: 'none', textAlign: 'center' }}>
                            <h3>Baixe a versão para dispositivos iOS:</h3>
                            <a href="https://apps.apple.com/br/app/memo-verbos/id6741009334" target="_blank" rel="noopener noreferrer">iOS</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <button className='button1' onClick={goBack} style={{ bottom: '5px' }}>Sair</button>
            </div>
            <div className="column right" style={{ alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <table>
                    <tbody>
                    <tr style={{ height: '5px' }}>
                        <td style={{ height: '5px', hyphens: 'none' }}>
                            <h3><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">Canal:</a></h3>
                        </td>
                    </tr>
                    <tr style={{ height: '5px' }}>
                        <td style={{ height: '5px' }}>
                            <p><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${logoRef.current}`)} alt="Ícone do Canal" style={{ width: '200px', height: '200px' }} />
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DownloadLinks;
