import React, {useState, useEffect, useRef} from 'react';
import LazyLoad from 'react-lazyload';
import { Button, Table, Image } from 'react-bootstrap';
import './styles.css'; // Importe o arquivo CSS
import previousIcon from './Icons/previousIcon.png'; // O caminho para o ícone anterior
import nextIcon from './Icons/nextIcon.png'; // O caminho para o ícone próximo
import pauseIcon from './Icons/pauseIcon.png'
import infiniteplayIcon from './Icons/infiniteplayIcon.png'
import {participios1, Regulares, Irregulares} from './conjugacao2';
import {paradigmasp, Terminacoes, Pagina, VerbosTemposParaVerbosPS} from "./paradigmas"
import {
    playerIsPaused,
    playerIsPlaying,
    playerNewList,
    playerPause,
    playerPlay,
    playerPlayAndWait,
    playerSpeed,
    playerStopAndReset
} from "./musicPlayer";
// import logo from './logotipo2.png'; // O caminho para a imagem
// import paypalIcon from "./Icons/paypalIcon.png";

const ActivityPast = ({ goBack }) => {

    const logoRef=useRef('./logotipo2.png');
    const paypalIconRef=useRef('./Icons/paypalIcon.png');

    ////Variáveis do quadro principal
    const ordemParticipiosRef = useRef(0);
    const numeroParticipiosRef = useRef(0);
    const RegularesRef = useRef('     -     ');
    const IrregularesRef = useRef(' SER - TER ');
    const arquivoPRef = useRef("logotipo2");
    const cBSPassarParticipiosRef = useRef(false);
    const bPausarPRef  = useRef(false);
    const bRepetirPRef  = useRef(false);
    const conjugacaoParticipiosRef = useRef(participios1);
    const audiosPUrlRef = useRef([]); // Adicione esta referência no início do seu componente
    const audiosPRef = useRef([]);
    useRef(0);
//// Variáveis do quadro de seguidores de paradigmas
    const arquivoPSRef = useRef("logotipo2");
    const verboPSRef = useRef('-AR');
    const paginaPSRef = useRef('(1/1)');
    const listaPVerbosPSRef =useRef(paradigmasp);
    const listaVerboPSRef = useRef(paradigmasp);
    const ordemVerbosPSRef = useRef(0);
    //// Variáveis de ambos os quadros
    const [renderSt, setRender] = useState(0);
    const loadingRef = useRef(false);
    const hasRunRef = useRef(false);
    useRef(1000);
//// Funções do quadro principal
    const atualizarVetorVerbosPS = () => {
        let verboParadigmaP = VerbosTemposParaVerbosPS(conjugacaoParticipiosRef.current[ordemParticipiosRef.current]).substring(0, 2);
        listaVerboPSRef.current = listaPVerbosPSRef.current.filter(item => item.startsWith(verboParadigmaP));
    }

    // Função para atualizar os Participios
    const atualizarParticipios =() => {
        if (ordemParticipiosRef.current >= conjugacaoParticipiosRef.current.length) {
            ordemParticipiosRef.current=0;
        }
        arquivoPRef.current=conjugacaoParticipiosRef.current[ordemParticipiosRef.current].substring(0, 3);

        RegularesRef.current=Regulares(conjugacaoParticipiosRef.current[ordemParticipiosRef.current]);
        IrregularesRef.current=Irregulares(conjugacaoParticipiosRef.current[ordemParticipiosRef.current]);

        // Atualize OrdemParticipios
        ordemParticipiosRef.current= conjugacaoParticipiosRef.current.findIndex(participioX => participioX === conjugacaoParticipiosRef.current[ordemParticipiosRef.current]);
        atualizarVetorVerbosPS();
        ordemVerbosPSRef.current=listaVerboPSRef.current.findIndex(verboPSX => verboPSX.substring(0,2)===VerbosTemposParaVerbosPS(conjugacaoParticipiosRef.current[ordemParticipiosRef.current]).substring(0,2));
    };

    const pararAudiosP = async () =>{
        try {
            await playerStopAndReset();
            // configure seu player mais se necessário
        } catch (error) {
            console.error('Erro ao configurar o player: ', error);
            // Implemente uma maneira de lidar com o erro aqui. Seja fornecendo feedback ao usuário, tentando novamente ou padrão seguro.
        }
    };


    const tocarConjugacaoP = async () => {
        let arquivoAudioP, urlAudioP, urlAudioP00;
        let ActivityConfig = JSON.parse(localStorage.getItem('config'));
        if (ActivityConfig.PaisAudio === "Portugal"){
            arquivoAudioP = "p" + arquivoPRef.current;
        } else {
            arquivoAudioP = "b" + arquivoPRef.current;
        }

        urlAudioP = process.env.PUBLIC_URL + `/raw/${arquivoAudioP}.mp3`;
        urlAudioP00 = process.env.PUBLIC_URL + `/raw/virada.mp3`;
        // Defina audiosPRef.current aqui
        const construirVetoresP = async () => {
            let playCount=0;
            audiosPUrlRef.current=[];
            audiosPRef.current=[];
            while (playCount < ActivityConfig.NumeroAudios) {
                audiosPUrlRef.current.push(urlAudioP);
                playCount++;
            }
            console.log(3999);
            if (ActivityConfig.NumeroAudios==="0"){
                console.log(4000);
                audiosPUrlRef.current=[urlAudioP00];
                console.log(4001);
            }
            audiosPRef.current = audiosPUrlRef.current.map((url) => ({
                name: 'Particípios Irregulares',
                artist: 'Leonardo Pereira',
                url: url,
            }));

            await playerNewList(audiosPRef.current);
        };
        const tocarAudiosP = async () => {
            const speed = ActivityConfig.VelocidadeAudio / 100;
            await playerSpeed(speed);
            await playerPlayAndWait();
        };
        await pararAudiosP();
        await construirVetoresP();
        await tocarAudiosP();

    };

    const atualizarVerbosPS = () => {
        console.log(11);
        atualizarVetorVerbosPS();
        console.log(12);
        console.log(listaVerboPSRef.current);
        console.log(ordemVerbosPSRef.current);
        verboPSRef.current = listaVerboPSRef.current[ordemVerbosPSRef.current];
        console.log(13);
        console.log(verboPSRef.current);
        verboPSRef.current = Terminacoes(verboPSRef.current)+"\u00A0".repeat(4)+Pagina(verboPSRef.current);
        console.log(14);
        arquivoPSRef.current = listaVerboPSRef.current[ordemVerbosPSRef.current].substring(0,4);
        console.log(15);
        paginaPSRef.current = Pagina(listaVerboPSRef.current[ordemVerbosPSRef.current]);
        console.log(16);
        ordemVerbosPSRef.current = listaVerboPSRef.current.findIndex(paradigmaPX => paradigmaPX === listaVerboPSRef.current[ordemVerbosPSRef.current]);
        console.log(17);
    }

    ////Funções para ambos os quadros
    const forceRenderLocalA = async () => {
        //hasRunRef.current = false; // Atualize hasRunRef para false
        setRender(X => X + 1); // Força a renderização
    };

    const aplicarMudancaP = async () => {
        numeroParticipiosRef.current=8;
        conjugacaoParticipiosRef.current=participios1;

        // Chame as funções após a atualização do estado
        console.log(1);
        atualizarParticipios();
        console.log(2);
        atualizarVerbosPS();
        console.log(3);
        await forceRenderLocalA();
        await tocarConjugacaoP();
        console.log(4);
    }
    const forceRender = async () => {
        //hasRunRef.current = false; // Atualize hasRunRef para false
        await aplicarMudancaP();
        setRender(X => X + 1); // Força a renderização
    };
    const forceRenderLocal = () => {
        //hasRunRef.current = false; // Atualize hasRunRef para false
        setRender(X => X + 1); // Força a renderização
    };

    //Botões do quadro principal
    const handleParticipiosAnteriorClick = async (saltar) => {
        let newOrdemParticipios =ordemParticipiosRef.current - 1;

        if (newOrdemParticipios < 0) { newOrdemParticipios = conjugacaoParticipiosRef.current.length - 1; }

        ordemParticipiosRef.current= newOrdemParticipios;
        if (bPausarPRef.current) {
            bPausarPRef.current = false;
        }
        if (saltar===true) {
        } else {
            await forceRender();
        }
    };


    const handleParticipiosPosteriorClick = async (saltar) => {
        let newOrdemParticipios =ordemParticipiosRef.current + 1;

        if (newOrdemParticipios >= conjugacaoParticipiosRef.current.length) { newOrdemParticipios = 0; }

        ordemParticipiosRef.current= newOrdemParticipios;
        if (bPausarPRef.current) {
            bPausarPRef.current = false;
        }
        if (saltar===true) {
        } else {
            await forceRender();
        }
    };

    const handlePassarParticipiosClick = async () => {

        cBSPassarParticipiosRef.current=!cBSPassarParticipiosRef.current;
        await forceRenderLocalA(); // Forçar renderização

        if (cBSPassarParticipiosRef.current) {
            await handleParticipiosAnteriorClick(true);

            while (cBSPassarParticipiosRef.current) {
                bRepetirPRef.current = false;
                bPausarPRef.current = false;

                await pararAudiosP();
                await handleParticipiosPosteriorClick(false);
                await forceRenderLocalA();
            }
        }
    };

    const  handlePausarPClick = async () => {
        const isPlaying = await playerIsPlaying();
        const isPaused = await playerIsPaused();

        if (isPlaying) {
            bPausarPRef.current = true;
            await playerPause();
        } else if (isPaused) {
            bPausarPRef.current = false;
            await playerPlay();
        }

        await forceRenderLocalA();
    };

    const handleExitClick = async () => {
        if (cBSPassarParticipiosRef.current) {
            await handlePassarParticipiosClick();
        }
        if (bRepetirPRef.current) {
            await handleRepetirPClick();
        }
        await pararAudiosP();
        goBack();
    };

    const handleRepetirPClick = async () => {
        bRepetirPRef.current = !bRepetirPRef.current
        await forceRenderLocalA();
        if (bRepetirPRef.current) {
            cBSPassarParticipiosRef.current = false;
            bPausarPRef.current = false;
        }
        while (bRepetirPRef.current) {
            await forceRender();
        }
    };


    //Botões dos seguidores de paradigma
    const handleSeguidorPAnteriorClick = () => {
        let newOrdemVerbosPS =ordemVerbosPSRef.current - 1;

        if (newOrdemVerbosPS < 0) { newOrdemVerbosPS = listaVerboPSRef.current.length - 1; }

        ordemVerbosPSRef.current= newOrdemVerbosPS;
        atualizarVerbosPS();
        forceRenderLocal();
    };

    const handleSeguidorPPosteriorClick = () => {
        let newOrdemVerbosPS =ordemVerbosPSRef.current + 1;

        if (newOrdemVerbosPS>= listaVerboPSRef.current.length) { newOrdemVerbosPS = 0; }

        ordemVerbosPSRef.current= newOrdemVerbosPS;
        atualizarVerbosPS();
        forceRenderLocal();
    };

    // Atualize os estados com base na seleção quando o componente for montado ou a seleção mudar
    useEffect(() => {
        if (!hasRunRef.current) {
            forceRenderLocal();
            aplicarMudancaP().then();
            hasRunRef.current = true;
        }
        loadingRef.current = true;
        console.log('Render:',renderSt);
    });

    if (loadingRef.current===false) {
        return null; // Ou seu componente de carregamento personalizado
    }


    return (
        <div className="App">
            <div className="column left" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">Doação:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${paypalIconRef.current}`)} alt="Ícone do PayPal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="column center2"  style={{flexDirection: 'column', alignItems: 'center'}}>
                <h3 style= {{marginTop: '0', marginBottom: '0'}}>{verboPSRef.current}</h3>
                <Image
                    src={process.env.PUBLIC_URL + `/drawable2/${arquivoPSRef.current}.png`}
                    alt="Paradigmas"
                    onError={(e) => {e.target.onerror = null; e.target.src=require(`${logoRef.current}`)}}
                    style={{width: '260px', height: '459px', position: 'absolute', bottom: '45px'}}
                />
                <Table style={{position: 'absolute', bottom: '3px'}}>
                    <tbody>
                    <tr>
                        <td>
                            <Button id="irSeguidorAnterior" className='button5' onClick={handleSeguidorPAnteriorClick} style={{marginRight: '20px', backgroundImage: `url(${previousIcon})`}}></Button>
                        </td>
                        <td>
                            <Button id="irSeguidorPosterior" className='button5' onClick={handleSeguidorPPosteriorClick} style={{backgroundImage: `url(${nextIcon})`}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className="column center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h3 style={{ marginBottom: '0',marginTop: '0' }}>PARTÍCIPIOS PASSADOS</h3>
                <h3 style={{ marginBottom: '1px', marginTop: '0'}}>IRREGULARES</h3>
                <Table style={{ margin: '0' }}>
                    <tbody>
                    <tr>
                        <td><h3 style={{ marginBottom: '1px' }}>Regulares:</h3></td>
                        <td><h3 style={{ marginBottom: '1px' }}>{RegularesRef.current}</h3></td>
                    </tr>
                    <tr>
                        <td><h3 style={{ marginBottom: '1px' }}>Irregulares:{'\u00A0'}</h3></td>
                        <td><h3 style={{ marginBottom: '1px' }}>{IrregularesRef.current}</h3></td>
                    </tr>
                    </tbody>
                </Table>
                <LazyLoad
                    height={200}
                    offset={[-200, 200]}
                    placeholder={<img src={require(`${logoRef.current}`)} alt="Imagem da conjugação"
                                      style={{width: '257px', height: '266px', objectFit: 'contain', objectPosition: 'center'}} />}
                >
                    <div style={{width: '257px', height: '262px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Image
                            src={process.env.PUBLIC_URL + `/drawable/${arquivoPRef.current}.png`}
                            alt="Imagem da conjugação"
                            onError={(e) => {e.target.onerror = null; e.target.src=require(`${logoRef.current}`)}}
                            style={{maxWidth: '100%',  height: 'auto'}}
                        />
                    </div>
                </LazyLoad>
                <h5 style={{ marginBottom: '40px' }}>{'\u00A0'}</h5>
                <Table style={{ margin: '0' }}>
                    <tbody>
                    <tr>
                        <td>{'\u00A0'.repeat(9)}</td>
                        <td style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{width: '30px'}}></span>
                            <Button id="irParticipiosAnterior" className='button5'
                                    onClick={handleParticipiosAnteriorClick}
                                    style={{backgroundImage: `url(${previousIcon})`}}></Button>
                            <span style={{width: '30px'}}></span>
                            <Button id="irParticipiosPosterior" className='button5'
                                    onClick={handleParticipiosPosteriorClick}
                                    style={{backgroundImage: `url(${nextIcon})`}}></Button>
                            <span style={{width: '30px'}}></span>
                            <Button id="passarParticipios" className={cBSPassarParticipiosRef.current ? 'button6On' : 'button6Off'}
                                    onClick={handlePassarParticipiosClick}
                                    style={{backgroundImage: `url(${infiniteplayIcon})`}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
                <Table style={{position: 'absolute', bottom: '1px'}}>
                    <tbody>
                    <tr>
                        <td style={{display: 'flex', justifyContent: 'center'}}>
                            <Button id="parar" className={bPausarPRef.current ? 'button3On' : 'button3Off'}
                                    onClick={handlePausarPClick}
                                    style={{backgroundImage: `url(${pauseIcon})`}}></Button>
                        </td>
                        <td><Button id="sair" className='button2' onClick={handleExitClick}>Sair</Button></td>
                        <td style={{display: 'flex', justifyContent: 'center'}}>
                            <Button id="repetir" className={bRepetirPRef.current ? 'button3On' : 'button3Off'}
                                    onClick={handleRepetirPClick}
                                    style={{backgroundImage: `url(${infiniteplayIcon})`}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className="column right" style={{alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank"
                                   rel="noopener noreferrer">Canal:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${logoRef.current}`)} alt="Ícone do Canal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ActivityPast;
